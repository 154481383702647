.SwipeBox {
  position: relative;
  height: 100%;
}

.swiper {
  position: absolute;
  height: 100%;
}

SwiperSlide {
  margin: 10%;
  height: 100%;
}

.SwipeBox .Frame {
  top: 5%;
  height: 90%;
}

.Card {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.swiper-pagination-bullet {
  transform: translateY(-1px);
}

.swiper-pagination-bullet-active {
  background-color: rgba(93, 0, 120, 1) !important;
}

.swiper-pagination-horizontal {
  width: fit-content !important;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 15;
  transform: translateX(-50%) scale(0.7) !important;
  left: 50% !important;
}

.Text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 10%;
  padding-right: 20%;
  height: fit-content;
  overflow: hidden;
}

.SwipeBox .font-title {
  font-size: 1.2vw;
}

.SwipeBox .font-italic {
  font-size: 0.7vw;
}

.SwipeBox .font-text {
  font-size: 0.85vw;
}

video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.MediaWrapper {
  width: 90%;
}

.Content {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  margin-bottom: 15px;
  border: 0;
}

.Projects .Link {
  position: relative;
  left: 35%;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: background 0.5s;
}

.Projects .Link:hover,
.inactive:hover {
  background: rgba(155, 155, 155, 0.12);
}

.ArcGPT,
.DHCS {
  aspect-ratio: 11/8;
}

.InTheZone {
  aspect-ratio: 16/13;
}

.Rush {
  aspect-ratio: 34/22;
}

.Eruca,
.Sweater {
  aspect-ratio: 7/4;
}

.Selector {
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  width: fit-content;
  text-align: center;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 0px 5px;
}

button {
  font-family: "Roboto Serif", serif;
  font-weight: 400;
  font-stretch: 112.5%;
  font-style: italic;
  font-variation-settings: "wdth" 112.5, "GRAD" -50;
  font-size: 0.6vw;
  color: #fce8fc;
  width: 6vw;
  display: inline-block;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 5px;
}

.inactive {
  border: 0;
  transition: background 0.5s;
  background: rgba(255, 255, 255, 0.12);
}

.active {
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  background: rgba(255, 255, 255, 0.2);
}

.swiper-button-next.swiper-button-next,
.swiper-button-prev.swiper-button-prev {
  color: #fce8fc;
  background: rgba(200, 200, 200, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  z-index: 15;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(150, 150, 150, 0.4);
}
