.Mobile {
  height: 100vh;
  overflow: hidden;
}

.Mobile p {
  text-align: center;
  margin-top: 40vh;
  padding: 0 15%;
}
