.About {
  scroll-snap-align: start;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.P2 {
  margin-top: 100vh;
}

.Info {
  padding: 0 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.InfoWrapper {
  height: 100%;
  position: relative;
}

.ColumnsWrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.About h2 {
  font-size: 2.5vw;
  text-align: center;
}

.About p {
  font-size: 1.1vw;
}

.About .Link {
  width: 100%;
  text-align: center;
}

.Link a {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: background 0.5s;
}

.Link a:hover {
  background: rgba(155, 155, 155, 0.12);
}

.LinkWrapper .font-italic {
  font-size: 1.1vw;
}

.About .LinkWrapper {
  margin-top: 5vh;
  width: 100%;
}

.SocialWrapper {
  text-align: center;
  font-size: 3vw;
}

.SocialWrapper svg {
  width: 2vw;
  margin: 1vw;
  color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: color 0.5s;
}

.SocialWrapper svg:hover {
  color: rgba(200, 200, 200, 0.7);
}

.Sphere {
  border-left: 5px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  position: relative;
  width: 100%;
}

.TagWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.TagWrapper span {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 1vw;
}

.Sphere button {
  width: 9vw;
  margin: 5px 1vw;
  font-size: 1vw;
}

.tagcloud {
  transform: translate(-50%, -5%);
  left: 50%;
}

.About .Selector {
  width: 35vw;
}