@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wdth,wght,GRAD@0,8..144,112.5,700,-50;0,8..144,125,400,100;1,8..144,112.5,400,-50&display=swap");

.font-text {
  font-family: "Roboto Serif", serif;
  font-weight: 400;
  font-stretch: 125%;
  font-variation-settings: "GRAD" 100;
}

.font-title {
  font-family: "Roboto Serif", serif;
  font-weight: 700;
  font-stretch: 112.5%;
  font-variation-settings: "GRAD" -50;
}

.font-italic {
  font-family: "Roboto Serif", serif;
  font-weight: 400;
  font-stretch: 112.5%;
  font-style: italic;
  font-variation-settings: "wdth" 112.5, "GRAD" -50;
}

.highlight {
  color: #ffc5e3;
}

body {
  margin: 0;
  padding: 0;
  background: fixed;
  background-repeat: no-repeat;
  background: rgb(28, 0, 36);
  background: linear-gradient(
    150deg,
    rgba(28, 0, 36, 1) 0%,
    rgba(93, 0, 120, 1) 30%,
    rgba(129, 0, 166, 1) 51%,
    rgba(199, 0, 103, 1) 100%
  );
  background-attachment: fixed;
  color: #fce8fc;
  font-family: "Fraunces";
}

a {
  color: #fce8fc;
  text-decoration: none; /* no underline */
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.Frame {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  position: absolute;
  transform: translate(-50%, -50%);
}

.Main {
  -webkit-backdrop-filter: blur(13.5px);
  backdrop-filter: blur(13.5px);
  height: 85%;
  width: 85%;
  top: 50%;
  left: 50%;
}

.Over {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 10;
}
