.Projects {
  scroll-snap-align: start;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.P3 {
  margin-top: 200vh;
}
