.Navbar {
    z-index: 30;
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    top: 3vh;
}

.Navbar .Selector {
    background: rgba(255, 255, 255, 0.2);
}

.Navbar button {
    font-size: 0.9vw !important;
    width: 8vw;
}