.Home {
  scroll-snap-align: start;
  height: 100vh;
}

.Intro {
  position: absolute;
  transform: translateY(-50%);
  top: 40%;
  margin-left: 10%;
  font-size: 1.5vw;
}

.Cursor {
  position: absolute;
  height: 60%;
  width: 40%;
  transform: translate(55vw, 25vh);
  text-align: center;
}

.Cursor p {
  transform: translateY(-60px);
}

.Image {
  position: absolute;
  height: 35%;
  aspect-ratio: 1/1;
  transform: translate(15vw, 60vh);
}

.img {
  content: url('../assets/portrait.png');
  height: 90%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}